const regexUrl = /(?:https?:\/\/)?(?:www\.)?(([-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b)+){1}(\/[\/\d\w\.\+-]*)*((?:[\?]){1}(\S+)*)*(\#(\S+)*)*/gi;
const regexEmail = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
const regexPhone = /((?:([+]\d{1,4})[-.\s]?)?(?:[(](\d{1,3})[)][-.\s]?)?(\d{1,4})[-.\s]?(\d{1,4})[-.\s]?(\d{1,9})){2}/g;
const regexDate = /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/;

const iconArrowRedirect = '<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 20 20"><path d="M17 17H3V3h5V1H3a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-5h-2z"/><path d="m11 1l3.29 3.29l-5.73 5.73l1.42 1.42l5.73-5.73L19 9V1z"/></svg>';

let langStrings = 'en';
let typeTextForHyperLinks = 1; // 1 => Texto, 2 => Icono, 3 => Texto e icono.


/**
 * Del string se obtiene urls, emails, telefonos, para reemplazarlos por hipervinculos, utilizando el HTML.
 * @param {*} string 
 * @returns 
 */
export const processString = ( string, lang = 'en', typeTextHyperLinks = 1 ) => {
    const arrayElements = getArrayElements( string );

    langStrings = lang;

    if( typeTextHyperLinks > 0 && typeTextHyperLinks < 4 )
        typeTextForHyperLinks = typeTextHyperLinks;
    
    return getStringWithHiperlinks( string, arrayElements );
}

/**
 * Retorna un array con phones, emails y websites, encontrados en el string.
 * @param {*} string 
 * @returns 
 */
export const getContactElementsFromString = ( string ) => {
    const arrayElements = getArrayElements( string );

    // console.log("Elementos de contacto del mensaje", arrayElements);

    const phones = [];
    const emails = [];
    const websites = [];

    if( arrayElements.length )
    arrayElements.map( item => {

        if( checkStringIsEmail( item ) )
            emails.push( item );

        else if( checkStringIsPhone( item ) && !checkStringIsUrl( item ) )
            phones.push( item );

        else
        {
            const urlHeader = checkIfStringHasHttp( item ) ? '' : 'https://';

            websites.push( urlHeader + item );
        }

    } );

    return { 
        phones: getUniqueValuesInArray(phones), 
        emails: getUniqueValuesInArray(emails), 
        websites: getUniqueValuesInArray(websites) 
    };
}

const getArrayElements = ( string ) => {
    var arrayElements = [];

    const arrayUrlsAndEmails = getElementsWithRegex( regexUrl, string );

    if( arrayUrlsAndEmails !== null )
    arrayElements = arrayElements.concat( arrayUrlsAndEmails );

    const arrayPhones = getElementsWithRegex( regexPhone, string );

    if( arrayPhones !== null )
    {
        // const arrayPhoneToDiscard = [];

        // // Verifica las url, si un phone se encuentra en las urls, entonces se agrega el phone al array para descartarlo.
        // if( arrayUrlsAndEmails.length )
        // {
        //     arrayPhones.map( itemPhone => {

        //         for (let index = 0; index < arrayUrlsAndEmails.length; index++) {
        //             const elementUrlAndEmail = arrayUrlsAndEmails[index];

        //             if( elementUrlAndEmail.indexOf( itemPhone ) !== -1 )
        //             {
        //                 arrayPhoneToDiscard.push( itemPhone );
        //                 console.log("encontre una", itemPhone);
        //             }
        //         }
        //     } );
        // }

        arrayElements = arrayElements.concat( 
            arrayPhones.filter( 
                phone => {
                    const result = !checkStringIsDate( phone ) /* && !arrayPhoneToDiscard.includes( phone ) */;

                    // if( arrayPhoneToDiscard.indexOf( phone ) !== -1 )
                    //     arrayPhoneToDiscard.splice( arrayPhoneToDiscard.indexOf( phone ), 1 );

                    return result;
                }
            )
        );
    }

    return arrayElements;
}

const getElementsWithRegex = ( regex, string ) => {
    var matches = string.match( regex );

    if( matches !== null )
    matches = matches.filter( n => n != "" );

    // console.log( "El resultado es", matches );

    return matches;
}

const getStringWithHiperlinks = ( string, elements ) => {

    if( !string.length || !elements.length ) return string;

    // Si el string tiene HTML se inserta en el target si se retorna.
    if( isHTML( string ) )
    {
        // targetFinal.innerHTML = string;
        return string;
    }

    // Recorriendo el array de elementos, buscando cada elemento en el string para replazarlo con una referencia.
    let finalString = replaceElementsInString( 1, string, elements );

    // Otra vez recorriendo el array de elementos para replazar los elementos de referencia en el string
    // con un element HTML etiqueta "a".
    finalString = replaceElementsInString( 2, finalString, elements );

    // console.log( "Replazando los elementos con referencias", finalString );

    return finalString;
}

const getUniqueValuesInArray = ( array ) => ( [ ...new Set( array ) ] );

const createStringReference = ( index ) => `[[{{--${ index }--}}]]`;

/**
 * Creando un elemento HTML, etiqueta a.
 * Se verifica el tipo de elemento que es para asignarle un prefijo en el href.
 * @param {*} string 
 * @param {*} verifyType 
 * @returns 
 */
const createA = ( string ) => {
    const elementA = document.createElement("a");

    // Asignando el texto a la etiqueta A.
    // elementA.textContent = string; // Asignando el texto del mismo href.
    // elementA.textContent = langStrings == 'en' ? 'here' : 'aqui'; // Asignando un texto común para todas las etiquetas A.
    // elementA.innerHTML = iconArrowRedirect; // Asignando un icono.
    elementA.innerHTML = typeTextForHyperLinks == 1 ? string : ( 
        typeTextForHyperLinks == 2 ? iconArrowRedirect : `${string} ${iconArrowRedirect}`
    );

    if( checkStringIsEmail( string ) )
    {
        elementA.href = `mailto:${ string }`;
    }
    else if( checkStringIsPhone( string ) && !checkStringIsUrl( string ) )
    {
        elementA.href = `tel:${ string }`;
    }
    else
    {
        elementA.href = checkIfStringHasHttp( string ) ? string : `https://${ string }`;
        elementA.target = "_blank";
    }

    return elementA;
}

/**
 * Recorriendo el array de elementos, buscando cada elemento en el string para replazarlo con una referencia.
 * @param {*} option 
 * @param {*} string 
 * @param {*} elements 
 * @returns 
 */
const replaceElementsInString = ( option, string, elements ) => {

    // Si option es 1, buscara los elementos en el string y los replazara con una referencia.
    // Si option es 2, buscara las referencias en el string y los replazara con una los elementos con html.

    elements.map( ( item, index ) => {

        const itemReference = createStringReference( index );
        const elementToFind = option == 1 ? item : itemReference ;

        if( string.indexOf( elementToFind ) !== -1 )
        {
            const elementForReplace = option == 1 ? itemReference : createA( item ).outerHTML;
            
            string = string.replace( elementToFind, elementForReplace );
            
            // console.log('array map option ', option, elementToFind, elementForReplace)
        }

    } );

    return string;
}

const checkStringIsEmail = ( string ) => string.match( regexEmail ) !== null;

const checkStringIsPhone = ( string ) => string.match( regexPhone ) !== null;

const checkStringIsDate = ( string ) => string.match( regexDate ) !== null;

const checkStringIsUrl = ( string ) => string.match( regexUrl ) !== null;

const checkIfStringHasHttp = ( string ) => string.includes( 'http://' ) || string.includes( 'https://' );

/**
 * Verifica si el string contiene etiquetas HTML.
 * @param {*} string 
 * @returns 
 */
export const isHTML = (string) => {
    return Array.from(new DOMParser().parseFromString(string, "text/html").body.childNodes).some(({ nodeType }) => nodeType == 1);
}